import React, { useContext } from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import SEO from "../../components/Layout/SEO";
import SuccessStories from "../../sections/camelo/SuccessStories";

import bgSection from "../../assets/image/customers/ups_store_creekside_large.png";

const MiddleCTA = () => {
  return (
    <Row className="bg-default-1 border-top">
      <Container>
        <Col xs="12" className="text-center pb-24">
          <h2 className="gr-text-5 pt-24 pb-8">
            <span
              className="highlighted1"
              style={{ backgroundPosition: "0 20px" }}
            >
              Try Camelo now and see the difference yourself.
            </span>
          </h2>
          <div>
            <Link
              onClick={() => {
                if (typeof window.gtag_report_conversion === "function") {
                  window.gtag_report_conversion(
                    process.env.SIGNUP_URL,
                  );
                }
              }}
              to={process.env.SIGNUP_URL}
              target="__blank"
              className="btn btn-primary with-icon gr-hover-y"
            >
              Start scheduling free
              <i className="icon icon-tail-right font-weight-bold"></i>
            </Link>
            <p className="gr-text-12 pt-8">
              59 companies signed up in the last week alone!
            </p>
          </div>
        </Col>
      </Container>
    </Row>
  );
};

const NailStory = () => {
  return (
    <>
      <PageWrapper>
        <SEO
          title="How Camelo helps The UPS Store Creekside manage schedules, messages, and tasks better"
          description="Find out how the Camelo employee scheduling app transforms scheduling, event organization, & communication for Whittlesey Christian Church."
        ></SEO>
        <div className="inner-banner bg-default-6 pt-24 pt-lg-30">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9">
                <div className="px-md-12 mb-13 text-center">
                  <h1 className="title gr-text-4 mb-8 mb-lg-12">
                    How Camelo helps The UPS Store Creekside manage schedules,
                    messages, and tasks better
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className="video-section py-15 py-sm-20 py-lg-35 dark-mode-texts "
          css={`
            background-image: url(${bgSection});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          `}
        />

        <div className="pb-2 pb-lg-9 pt-10 pt-lg-15 bg-default-6 customer-story">
          <Container>
            <Row className="justify-content-center">
              <Col xl="10" lg="10">
                <div className="px-md-12 text-black mb-13 mb-lg-22">
                  <p className="gr-text-8 text-black">
                    <Link
                      to="https://www.instagram.com/theupsstorecreekside"
                      target="__blank"
                    >
                      {`The UPS Store Creekside `}
                    </Link>
                    is a family-owned store that provides packing, shipping, and
                    printing services. They used Excel for managing schedules,
                    group texts for communicating, and photos for keeping daily
                    schedules. After testing over 40 apps,
                    <Link
                      to="https://www.facebook.com/josh.bargmann.3"
                      target="__blank"
                    >
                      {` Joshua`}
                    </Link>
                    —assistant manager of The UPS Store Creekside—found Camelo
                    and saved so much time with scheduling, making
                    announcements, and keeping the team accountable.
                  </p>
                  <h4 className="pt-8 pb-4">
                    A business adventure of two brothers
                  </h4>
                  <p className="gr-text-8 text-black">
                    The UPS Store Creekside was started by two brothers looking
                    for a new business adventure. With Collin’s knowledge of
                    shipping and Keith’s love of printing and production, the
                    store has thrived for over 10 years.
                  </p>
                  <p className="gr-text-8 text-black">
                    The store helps customers with packing, shipping, and
                    printing needs. They print everything, from invitations,
                    brochures, and business cards to large banners, fat heads,
                    and canvas artwork.
                  </p>

                  <h4 className="pt-8 pb-4">
                    Leading by example and making sure everyone felt heard
                  </h4>

                  <p className="gr-text-8 text-black">
                    We had the opportunity to talk to Joshua, the assistant
                    manager of The UPS Store Creekside. We asked about the
                    difficulties in managing the store and the scheduling
                    process, and Joshua shared:
                  </p>

                  <blockquote className="blockquote mb-4 card-body">
                    <p className="gr-text-8 text-black">
                      “My struggle was to find a balance between a laid-back and
                      fun atmosphere while maintaining professionalism at work.
                      I discovered that leading by example helped to balance
                      these aspects of our store, making sure every employee
                      felt heard and accepted.”
                    </p>
                  </blockquote>

                  <h4 className="pt-8 pb-4">
                    It was hard to manage shift swaps and daily schedules
                  </h4>
                  <p className="gr-text-8 text-black">
                    Before discovering Camelo, Joshua and the team used
                    Microsoft Excel to schedule employees. The process was
                    time-consuming as manual scheduling led to many problems.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="gr-text-8 text-black">
                      “It was also difficult to keep up with shift swaps on a
                      physical schedule and we were having to take photos so
                      people could see their schedules day to day,” shared
                      Joshua.
                    </p>
                  </blockquote>

                  <h4 className="pt-8 pb-4">
                    Messy group texts and threads were confusing
                  </h4>
                  <p className="gr-text-8 text-black">
                    And it took a lot of work to communicate with the team.
                  </p>

                  <blockquote className="blockquote mb-4 card-body">
                    <p className="gr-text-8 text-black">
                      “There was a time when we had to have a group text for
                      messaging but with some Android users, we had to create
                      all new threads when someone left or a new employee was
                      added and some messages were sent to the wrong threads
                      leading to confusion,” said Joshua.
                    </p>
                  </blockquote>

                  <h4 className="pt-8 pb-4">
                    Trying over 40 apps to modernize the scheduling process
                  </h4>
                  <p className="gr-text-8 text-black">
                    So Joshua came looking for a solution to modernize the
                    scheduling process with 4 main things in mind: the ability
                    to schedule, message employees, shift swapping capabilities,
                    and all at a great price.
                  </p>

                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “After testing over 40 apps, Camelo was by far the best.”
                    </p>
                  </blockquote>
                  <p className="gr-text-8 text-black">
                    With Camelo, Joshua can easily create and share schedules
                    with the team, and everyone gets updates about their shifts
                    through the mobile app. The team can send messages in groups
                    or individually, keeping communication organized. They can
                    also swap shifts quickly with automated suggestions and
                    notifications. All these cool features come with an
                    affordable plan and quick support.
                  </p>
                  <p className="gr-text-8 text-black">
                    The challenges of messaging the team and keeping up with
                    shift swaps and daily schedules were quickly solved when The
                    UPS Store Creekside team started using Camelo.
                  </p>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “Camelo made this super simple and everything is now in
                      the palm of our hands and updated live.”
                    </p>
                  </blockquote>
                  <h4 className="pt-8 pb-4">What Joshua loves about Camelo</h4>
                  <p className="gr-text-8 text-black">
                    What Joshua loves about Camelo is shift templates, task
                    management, and quick, helpful support. He would also
                    recommend Camelo to others because of the ease of the
                    platform as well as its capabilities.
                  </p>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “Love the ability to easily schedule with pre-made shifts
                      and daily tasks. Mai with support has always been quick to
                      answer and always so helpful.”
                    </p>
                  </blockquote>
                  <p className="gr-text-8 text-black">
                    As we’ve constantly updated and added new capabilities to
                    the app to make Camelo more versatile and convenient for
                    users, we’ve also invited Joshua and his team to be the
                    first to try out our new features.
                  </p>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “Love Camelo and cannot wait to see what the future holds
                      with new updates and features!” Joshua said.
                    </p>
                  </blockquote>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <MiddleCTA />
        <SuccessStories />
      </PageWrapper>
    </>
  );
};
export default NailStory;
